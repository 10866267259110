const merge = require('lodash/merge')

const { fr } = require('./i18n/config')

const getEnvironmentFromUrl = require('./utils/url')

const apiUrls = {
  qa1: 'https://api.qa1.quotatis.com',
  newqa: 'https://api.qa.quotatis.com',
  newpreprod: 'https://api.preprod.quotatis.com',
  newprod: 'https://api.prod.quotatis.com',
}

const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const currentEnv = getEnvironmentFromUrl(hostname, 'www', 'suividepose.fr')

const configs = {
  all: {
    env: currentEnv,
    api: {
      url: 'https://api.qarx.io',
      clientId: '4qhq3n20xi4gww0gokc0k44k0ss48ssw4g88kgg8kkkscgco0k',
      clientSecret: '4aoyh39n19usgos8ss0osscwg8ogkgkg0wcw0wkkg0kkow8gwc',
      kongApiKey: 'Mhei5u7gzROiAaCQ1JXFXZJvamUie74E',
    },
    sylius: {
      url: apiUrls.newqa,
    },
    proApi: {
      url: `${apiUrls.newqa}/pro`,
    },
    hoApi: {
      url: `${apiUrls.newqa}/home-owner`,
    },
    engine: {
      url: apiUrls.newqa,
    },
    billing: {
      url: `${apiUrls.newqa}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.newqa}/documents`,
    },
    frontApi: {
      url: `${apiUrls.newqa}/front-api`,
    },
    survey: {
      url: `${apiUrls.newqa}/survey`,
    },
    youSign: {
      signatureUis: '/signature_uis/9588b17f-90bc-49df-8bc1-e7cb5d796638',
    },
    faqPage: 'https://quotat.is/AideClient',
    directoryPage: {
      url: 'https://annuaire.qa.quotatis.fr',
    },
    google: {
      reCaptchaKey: '6Le_7F4aAAAAAEiOhDH3V2zVcLImXxVJJYvNaMXN',
      mapsKey: 'AIzaSyCYrFIvVAYqnWCNjjAfGIanmwxVvj1FPj8',
      grantType: 'https://www.quotatis.com/google',
      clientId:
        '726740106708-5fh1f42gq45ek5a5g2p7olldjt679qjg.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/plus.login',
      tagManagerId: {
        poseTracking: 'GTM-KTZW92T',
        FR: 'GTM-N58NQW',
        ES: 'GTM-K9GWBNM',
      },
      centerGeometry: {
        FR: {
          lat: 46.8152,
          lng: 2.3988,
        },
        ES: {
          lat: 40.4637,
          lng: -3.7492,
        },
      },
      gtagTrackPose: 'G-FMB7PLX0GJ',
    },
    facebook: {
      grantType: 'https://www.quotatis.com/facebook',
      appId: '233630107058419',
      scope: 'public_profile, email, user_birthday, user_location',
    },
    algolia: {
      places: {
        appId: 'pl7H5VIIP0QS',
        apiKey: '1ea791cd7ecc80e52e574f660e5728fa',
      },
      search: {
        appId: 'TIM81UW1UV',
        apiKey: '833cbf062e3b7c3979155c0d07e3f058',
      },
      packagesIndex: {
        FR: 'qa_k8s_products_fr',
        ES: 'qa_k8s_products_es',
      },
      proFormIndex: {
        FR: 'qa_k8s_ProForm_fr',
        ES: 'qa_k8s_ProForm_es',
      },
      wordpressContentIndex: {
        FR: 'qa_k8s_content_fr',
        ES: 'qa_k8s_content_es',
      },
      firmIndex: 'qa_k8s_firms',
    },
    cloudinary: {
      url: 'https://res.cloudinary.com/quotatis/image/upload',
      app: 'FrontApp',
      api: 'https://api.cloudinary.com/v1_1',
      cloudName: 'quotatis',
      apiSecret: 'HOJZMYzlYfL5CnsOU-b0DiKS6Sk',
      apiKey: '758912458653982',
    },
    bazaarvoice: {
      clientName: 'quotatis',
      siteID: 'main_site',
    },
    defaultLocale: 'fr-FR',
    locales: {
      'fr-FR': fr,
      // 'es-ES': es,
    },
    trackingLocales: {
      'fr-FR': {
        url: 'https://www.local.suividepose.fr:4433',
      },
      'es-ES': {
        url: 'https://www.local.suividepose.fr:4433',
      },
    },
    termsUrl: 'https://www.quotatis.fr/conseils-travaux/cgu',
    sentry: {
      enabled: false,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      url: 'https://ddf111b5049a18f728c23d44da0a3622@o88364.ingest.us.sentry.io/4507051076747264',
    },
    wordpressContentCategories: {
      guides: ['project_page', 'project_page_element'],
      faqs: ['faqs', 'standards'],
      articles: ['inspirations'],
    },
    auth0: {
      domain: 'auth0.qa.quotatis.fr',
      ho: {
        clientID: 'ciGnXMY01zOPrxDWlYXob5vDojGpwZUG',
        audience: 'https://auth.qa.quotatis.com/ho/api',
      },
    },
    auth0Tracking: {
      domain: 'auth0.qa.suividepose.fr',
      audience: 'https://auth0.qa.suividepose.fr/logical-api',
      clientID: 'DuYe089LPCV2srmK1KCaCRUYDqLyR5D0',
      redirectUri: 'https://www.qa.suividepose.fr',
    },
    googleVision: {
      url: 'https://vision.googleapis.com/v1/images:annotate',
      apiKey: 'AIzaSyCt2P_oo0MMiWeo8z0feX2F8l4nbm5VLJ0',
      likelihood: {
        UNKNOWN: 0,
        VERY_UNLIKELY: 1,
        UNLIKELY: 2,
        POSSIBLE: 3,
        LIKELY: 4,
        VERY_LIKELY: 5,
      },
      threshold: {
        adult: 4,
        medical: 4,
        racy: 5,
        spoof: 5,
        violence: 4,
      },
    },
    mobileAppIds: {
      android: [
        'fr.quotatispro.quotatis.qa.debug',
        'fr.quotatispro.quotatis.qa',
      ],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis.qa',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.qa.debug',
      ],
    },
    firebaseConfig: {
      apiKey: 'AIzaSyAZSCHxv2EJakoo_0UqGuhTk_DiAwf7c5g',
      authDomain: 'fb-carol-newqa.firebaseapp.com',
      databaseURL: 'https://fb-carol-newqa.firebaseio.com',
      projectId: 'fb-carol-newqa',
      storageBucket: 'fb-carol-newqa.appspot.com',
      messagingSenderId: '235481442879',
      appId: '1:235481442879:web:d61a9c831f7cace61b668a',
      measurementId: 'G-8XGVPX21FJ',
    },
    firebaseTrackingConfig: {
      apiKey: 'AIzaSyCa-ro0sUgAEiWxl-EN01h-9FcdcXsa6qw',
      authDomain: 'fb-quotatis-app-qa-259d4.firebaseapp.com',
      databaseURL: 'https://fb-quotatis-app-qa-259d4.firebaseio.com',
      projectId: 'fb-quotatis-app-qa',
      storageBucket: 'fb-quotatis-app-qa.appspot.com',
      messagingSenderId: '623963683851',
      appId: '1:623963683851:web:8e0ebe020afc19c45edb0e',
      measurementId: 'G-371ZDDHHZX',
    },
  },
  qa1: {
    sylius: {
      url: apiUrls.qa1,
    },
    proApi: {
      url: `${apiUrls.qa1}/pro`,
    },
    hoApi: {
      url: `${apiUrls.qa1}/home-owner`,
    },
    engine: {
      url: apiUrls.qa1,
    },
    billing: {
      url: `${apiUrls.qa1}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.qa1}/documents`,
    },
    frontApi: {
      url: `${apiUrls.qa1}/front-api`,
    },
    survey: {
      url: `${apiUrls.qa1}/survey`,
    },
    algolia: {
      packagesIndex: {
        FR: 'qa1_k8s_products_fr',
        ES: 'qa1_k8s_products_es',
      },
      proFormIndex: {
        FR: 'qa1_k8s_ProForm_prod_fr',
        ES: 'qa1_k8s_ProForm_prod_es',
      },
      wordpressContentIndex: {
        FR: 'qa1_k8s_content_fr',
        ES: 'qa1_k8s_content_es',
      },
      firmIndex: 'qa1_k8s_firms',
    },
    auth0Tracking: {
      domain: 'auth0.qa1.suividepose.fr',
      audience: 'https://auth0.qa1.suividepose.fr/logical-api',
      clientID: 'diM98Zm7zMy5w1qYG70HvhfyGsNsTCaj',
      redirectUri: 'https://www.qa1.suividepose.fr',
    },
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://www.qa1.quotatis.fr',
      },
      'es-ES': {
        url: 'https://www.qa1.quotatis.es',
      },
    },
    trackingLocales: {
      'fr-FR': {
        url: 'https://www.qa1.suividepose.fr',
      },
      'es-ES': {
        url: 'https://www.qa1.suividepose.fr',
      },
    },
    auth0: {
      domain: 'auth0.qa1.quotatis.fr',
      ho: {
        clientID: 'EaIkW23OoD3dYbN67HFety6dGmZqsB7s',
        audience: 'https://auth0.qa1.quotatis.fr/ho/api',
      },
    },
    firebaseConfig: {
      apiKey: 'AIzaSyANCx6JsR6P6eSodax_dSZSql3kOrCIKbM',
      authDomain: 'fb-carol-qa1.firebaseapp.com',
      databaseURL: 'https://fb-carol-qa1.firebaseio.com',
      projectId: 'fb-carol-qa1',
      storageBucket: 'fb-carol-qa1.appspot.com',
      messagingSenderId: '910036832881',
      appId: '1:910036832881:web:66fea81d638631a9ad01d0',
      measurementId: 'G-5JKH152BLD',
    },
    google: {
      gtagTrackPose: 'G-FMB7PLX0GJ',
    },
    directoryPage: {
      url: 'https://annuaire.qa1.quotatis.fr',
    },
  },
  qa: {
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://www.qa.quotatis.fr',
      },
      'es-ES': {
        url: 'https://www.qa.quotatis.es',
      },
    },
    trackingLocales: {
      'fr-FR': {
        url: 'https://www.qa.suividepose.fr',
      },
      'es-ES': {
        url: 'https://www.qa.suividepose.fr',
      },
    },
    auth0: {
      domain: 'auth0.qa.quotatis.fr',
      ho: {
        clientID: 'V4yWnHwrlngyoBIO7RbBNndnxUjjsz79',
        audience: 'https://auth0.qa.quotatis.fr/ho/api',
      },
    },
    auth0Tracking: {
      domain: 'auth0.qa.suividepose.fr',
      audience: 'https://auth0.qa.suividepose.fr/logical-api',
      clientID: 'DuYe089LPCV2srmK1KCaCRUYDqLyR5D0',
      redirectUri: 'https://www.qa.suividepose.fr',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyAZSCHxv2EJakoo_0UqGuhTk_DiAwf7c5g',
      authDomain: 'fb-carol-newqa.firebaseapp.com',
      databaseURL: 'https://fb-carol-newqa.firebaseio.com',
      projectId: 'fb-carol-newqa',
      storageBucket: 'fb-carol-newqa.appspot.com',
      messagingSenderId: '235481442879',
      appId: '1:235481442879:web:d61a9c831f7cace61b668a',
      measurementId: 'G-8XGVPX21FJ',
    },
    google: {
      gtagTrackPose: 'G-FMB7PLX0GJ',
    },
  },
  preprod: {
    sylius: {
      url: apiUrls.newpreprod,
    },
    proApi: {
      url: `${apiUrls.newpreprod}/pro`,
    },
    hoApi: {
      url: `${apiUrls.newpreprod}/home-owner`,
    },
    engine: {
      url: apiUrls.newpreprod,
    },
    billing: {
      url: `${apiUrls.newpreprod}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.newpreprod}/documents`,
    },
    frontApi: {
      url: `${apiUrls.newpreprod}/front-api`,
    },
    survey: {
      url: `${apiUrls.newpreprod}/survey`,
    },
    api: {
      clientId: '21ujn3bgfgjoc8w08s0wgkwgc8s8g4gscggcc4skog8c0g4k8c',
      clientSecret: '5fbpmtbpwkcg0w8gk8ssg44084okg8co4koccssgws8ko0c440',
      url: 'https://api.preprod.quotatis.com',
    },
    google: {
      mapsKey: 'AIzaSyAsFOnh8HufqRe55p_7rCOQG0nxz22px4Q',
      clientId:
        '726740106708-1197l2ohju4kcpod6ugq2k87675t6ibs.apps.googleusercontent.com',
      gtagTrackPose: 'G-MJS0Y4QT1G',
    },
    facebook: {
      appId: '297437357370490',
    },
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://www.preprod.quotatis.fr',
      },
      'es-ES': {
        url: 'https://www.preprod.quotatis.es',
      },
    },
    trackingLocales: {
      'fr-FR': {
        url: 'https://www.preprod.suividepose.fr',
      },
      'es-ES': {
        url: 'https://www.preprod.suividepose.fr',
      },
    },
    algolia: {
      packagesIndex: {
        FR: 'preprod_k8s_products_fr',
        ES: 'preprod_k8s_products_es',
      },
      proFormIndex: {
        FR: 'preprod_k8s_ProForm_prod_fr',
        ES: 'preprod_k8s_ProForm_prod_es',
      },
      wordpressContentIndex: {
        FR: 'preprod_k8s_content_fr',
        ES: 'preprod_k8s_content_es',
      },
      firmIndex: 'preprod_k8s_firms',
    },
    auth0: {
      domain: 'auth0.preprod.quotatis.fr',
      ho: {
        clientID: 'afvVPM7HOZf7YdMWGLdKwrRforHKAe1v',
        audience: 'https://auth0.preprod.quotatis.fr/ho/api',
      },
    },
    auth0Tracking: {
      domain: 'auth0.preprod.suividepose.fr',
      audience: 'https://auth0.preprod.suividepose.fr/logical-api',
      clientID: 'EFudN3nmMCcWWPWMd37ZnapknBQE1Hht',
      redirectUri: 'https://www.preprod.suividepose.fr',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyASnZwYMMYoxZ21OWJORKdXkKaq_DPwu38',
      authDomain: 'fb-carol-newpreprod.firebaseapp.com',
      databaseURL: 'https://fb-carol-newpreprod.firebaseio.com',
      projectId: 'fb-carol-newpreprod',
      storageBucket: 'fb-carol-newpreprod.appspot.com',
      messagingSenderId: '153390929489',
      appId: '1:153390929489:web:64010c4e65ab67a8f9ca70',
      measurementId: 'G-JQDZWD37XC',
    },
    firebaseTrackingConfig: {
      apiKey: 'AIzaSyAkjgUOyFLgzFQEN6n4FA6UYIuwwAuWjzc',
      authDomain: 'fb-quotatis-app-preprod.firebaseapp.com',
      databaseURL: 'https://fb-quotatis-app-preprod.firebaseio.com',
      projectId: 'fb-quotatis-app-preprod',
      storageBucket: 'fb-quotatis-app-preprod.appspot.com',
      messagingSenderId: '263446270744',
      appId: '1:263446270744:web:119f4f3b610eb994ff046d',
      measurementId: 'G-JSLG46XQEE',
    },
    mobileAppIds: {
      android: [
        'fr.quotatispro.quotatis.preprod.debug',
        'fr.quotatispro.quotatis.preprod',
      ],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis.preprod',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.preprod.debug',
      ],
    },
    directoryPage: {
      url: 'https://annuaire.preprod.quotatis.fr',
    },
  },
  prod: {
    sylius: {
      url: apiUrls.newprod,
    },
    proApi: {
      url: `${apiUrls.newprod}/pro`,
    },
    hoApi: {
      url: `${apiUrls.newprod}/home-owner`,
    },
    engine: {
      url: apiUrls.newprod,
    },
    billing: {
      url: `${apiUrls.newprod}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.newprod}/documents`,
    },
    frontApi: {
      url: `${apiUrls.newprod}/front-api`,
    },
    survey: {
      url: `${apiUrls.newprod}/survey`,
    },
    api: {
      clientId: '21ujn3bgfgjoc8w08s0wgkwgc8s8g4gscggcc4skog8c0g4k8c',
      clientSecret: '5fbpmtbpwkcg0w8gk8ssg44084okg8co4koccssgws8ko0c440',
      url: 'https://api.prod.quotatis.com',
      kongApiKey: 'qjog89bumjDZfIJVMEUkxgK7jJOrm47y',
    },
    google: {
      mapsKey: 'AIzaSyAsFOnh8HufqRe55p_7rCOQG0nxz22px4Q',
      clientId:
        '726740106708-1197l2ohju4kcpod6ugq2k87675t6ibs.apps.googleusercontent.com',
      gtagTrackPose: 'G-5TB7W82W7C',
    },
    facebook: {
      appId: '297437357370490',
    },
    sentry: {
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://www.quotatis.fr',
      },
      'es-ES': {
        url: 'https://www.quotatis.es',
      },
    },
    trackingLocales: {
      'fr-FR': {
        url: 'https://www.suividepose.fr',
      },
      'es-ES': {
        url: 'https://www.suividepose.fr',
      },
    },
    algolia: {
      packagesIndex: {
        FR: 'prod_k8s_products_fr',
        ES: 'prod_k8s_products_es',
      },
      proFormIndex: {
        FR: 'prod_k8s_ProForm_prod_fr',
        ES: 'prod_k8s_ProForm_prod_es',
      },
      wordpressContentIndex: {
        FR: 'prod_k8s_content_fr',
        ES: 'prod_k8s_content_es',
      },
      firmIndex: 'prod_k8s_firms',
    },
    auth0: {
      domain: 'auth0.prod.quotatis.fr',
      ho: {
        clientID: 'ycEPGfhaHWTfVf1B4fC931UPY6oeXHsL',
        audience: 'https://auth0.prod.quotatis.fr/ho/api',
      },
    },
    auth0Tracking: {
      domain: 'auth0.prod.suividepose.fr',
      audience: 'https://auth0.prod.suividepose.fr/logical-api',
      clientID: 'BSbS3L9jUgWF7SLskduoGYqxev1AiWod',
      redirectUri: 'https://www.suividepose.fr',
    },
    youSign: {
      signatureUis: '/signature_uis/baebb6f6-6304-4f8c-8311-0a0d36f873aa',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyDPjidSWf0ZZHQOaVpkgxcO36kqSJRX7Y4',
      authDomain: 'fb-carol-newprod.firebaseapp.com',
      databaseURL: 'https://fb-carol-newprod.firebaseio.com',
      projectId: 'fb-carol-newprod',
      storageBucket: 'fb-carol-newprod.appspot.com',
      messagingSenderId: '117566274768',
      appId: '1:117566274768:web:8f90956ac647a748b3b2d5',
      measurementId: 'G-4TRXDPB7JM',
    },
    firebaseTrackingConfig: {
      apiKey: 'AIzaSyC-aU0U-SlrOqWwmNqwPfLVWZLNF0552t0',
      authDomain: 'fb-quotatis-app-prod.firebaseapp.com',
      databaseURL: 'https://fb-quotatis-app-prod.firebaseio.com',
      projectId: 'fb-quotatis-app-prod',
      storageBucket: 'fb-quotatis-app-prod.appspot.com',
      messagingSenderId: '878764101406',
      appId: '1:878764101406:web:ced89ec537a6ec7dad830f',
      measurementId: 'G-Z24QQ0LDZV',
    },
    mobileAppIds: {
      android: ['fr.quotatispro.quotatis.debug', 'fr.quotatispro.quotatis'],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.debug',
      ],
    },
    directoryPage: {
      url: 'https://annuaire.quotatis.fr',
    },
  },
  development: {
    locales: {
      'fr-FR': {
        url: 'https://www.local.quotatis.com:4433',
      },
      'es-ES': {
        url: 'https://www.local.quotatis.com:4433',
      },
    },
    auth0: {
      domain: 'auth0.qa.quotatis.fr',
      ho: {
        clientID: 'V4yWnHwrlngyoBIO7RbBNndnxUjjsz79',
        audience: 'https://auth0.qa.quotatis.fr/ho/api',
      },
    },
    auth0Tracking: {
      domain: 'auth0.qa.suividepose.fr',
      audience: 'https://auth0.qa.suividepose.fr/logical-api',
      clientID: 'DuYe089LPCV2srmK1KCaCRUYDqLyR5D0',
      redirectUri: 'https://www.local.suividepose.fr:4433',
    },
    // Uncomment in order to use engine in local
    // sylius: {
    //   url: 'https://sylius-dev.qarx.io:8090',
    // },
    // proApi: {
    //   url: 'https://pro-api.local.quotatis.com:8204',
    // },
    // engine: {
    //   url: 'https://engine.local.quotatis.com:8205',
    // },
    // billing: {
    //   url: 'https://billing.local.quotatis.com:8207',
    // },
    // documentsApi: {
    //   url: 'https://documents-api.local.quotatis.com:8202',
    // },
    // frontApi: {
    //   url: 'https://front-api.local.quotatis.com:8210',
    // },
    // survey: {
    //   url: 'https://survey.local.quotatis.com:8206',
    // },
  },
  mocks: {
    api: {
      url: 'https://www.local.quotatis.com:7001',
    },
    sylius: {
      url: 'https://www.local.quotatis.com:7001/sylius',
    },
    proApi: {
      url: 'https://www.local.quotatis.com:7001/pro-api',
    },
    hoApi: {
      url: 'https://www.local.quotatis.com:7001/ho-api',
    },
    engine: {
      url: 'https://www.local.quotatis.com:7001/engine',
    },
    billing: {
      url: 'https://www.local.quotatis.com:7001/billing',
    },
    documentsApi: {
      url: 'https://www.local.quotatis.com:7001/documents-api',
    },
    frontApi: {
      url: 'https://www.local.quotatis.com:7001/front-api',
    },
    survey: {
      url: 'https://www.local.quotatis.com:7001/survey',
    },
    locales: {
      'fr-FR': {
        url: 'https://www.local.quotatis.com:4321',
      },
      'es-ES': {
        url: 'https://www.local.quotatis.com:4321',
      },
    },
    mocking: true,
    auth0: {
      domain: 'www.local.quotatis.com:7001/auth0',
      clientID: 'ciGnXMY01zOPrxDWlYXob5vDojGpwZUG',
      audience: 'www.local.quotatis.com:7001/auth0/userinfo',
    },
  },
}

const config = merge(configs.all, configs[configs.all.env])

if (process.env.REACT_APP_API_URL) {
  config.api.url = process.env.REACT_APP_API_URL
}

if (process.env.REACT_APP_OVERRIDE_ENV) {
  ;['api', 'algolia'].forEach(key => {
    config[key] = merge(
      configs.all[key],
      configs[process.env.REACT_APP_OVERRIDE_ENV][key],
    )
  })
}

module.exports = config
